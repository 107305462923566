import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// import 'core-js/stable';
import "./scss/index.min.css";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import ReactGa from "react-ga";

import { StaticDataProvider } from "./config";

import MainRouter from "./router";
import ScrollRestoration from "./router/scrollRestoration";

import store from "redux/store.js";
import { isIE, isSafari } from "helper";

ReactGa.initialize("UA-147447473-6");
ReactGa.pageview(window.location.pathname + window.location.search);

if (isIE()) {
  document.body.classList.add("is-ie");
}

if (isSafari) {
  document.body.classList.add("is-safari");
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <StaticDataProvider>
        <ScrollRestoration>
          <MainRouter />
        </ScrollRestoration>
      </StaticDataProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
