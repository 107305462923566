import React, { useState, createContext } from "react";

export const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <LoaderContext.Provider value={{ loaded, setLoaded }}>
      {children}
    </LoaderContext.Provider>
  );
};
