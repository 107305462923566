import {
  SET_PERSONAL_DATA,
  SET_LAST_WATCHED_VIDEO,
  ADD_PRODUCT_TO_CART,
  SET_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_VIDEOS_IN_CART,
  UPDATE_PRODUCT_VIDEOS_IN_CART_REQUEST,
} from "../types";

import i18n from "i18next";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import logService from "services/logService";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  _id: "",
  code: "",
  name: "",
  company: "",
  email: "",
  category: "",
  productsInCart: [],
  products: [],
  productVideos: {
    lastWatched: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PERSONAL_DATA: {
      const {
        _id,
        code,
        name,
        company,
        email,
        category,
        manager,
        managerJob,
        managerMail,
        managerTel,
        managerMobile,
        products,
      } = action.data;

      const productArray = [];

      // for (let i = 0; i < products.length; i++) {
      //   productArray.push({
      //     name: products[i].productName,
      //     img: products[i].img,
      //     requested: false,
      //     qty: 0,
      //   });
      // }

      // add Stories
      if (category === "Ospelt Food & Snacks International") {
        for (let i = 0; i < products.length; i++) {
          productArray.push({
            name: products[i].productName,
            img: products[i].img,
            requested: false,
            qty: 0,
            category: products[i].category,
          });
        }
        // productArray.push({
        //   name: "Story Nonna Blanca",
        //   requested: true,
        //   qty: 0,
        //   img: "/assets/logos/nonna-blanca.png",
        // });
        // productArray.push({
        //   name: "Story Bio Fresh",
        //   requested: true,
        //   qty: 0,
        //   img: "/assets/logos/bio-fresh.png",
        // });
        // productArray.push({
        //   name: "Story Conower",
        //   requested: true,
        //   qty: 0,
        //   img: "/assets/logos/conower.png",
        // });
      }

      if (category === "Ospelt Petfood") {
        productArray.push({
          name: `${i18n.language === "de" ? "Sticks Select" : "Sticks Select"}`,
          requested: true,
          qty: 0,
          img: `/assets/product-images/Folder/${i18n.language}/Edelsticks.jpg`,
          // img: "/assets/product-folder/Edelsticks.jpg",
          url: `/assets/product-folder/Edelsticks_${i18n.language.toUpperCase()}_2021.pdf`,
        });
        productArray.push({
          name: `${i18n.language === "de" ? "Hunde Snacks" : "Dog Snacks"}`,
          requested: true,
          qty: 0,
          img: `/assets/product-images/Folder/${i18n.language}/Hundesnacks.jpg`,
          // img: "/assets/product-folder/Hundesnacks.jpg",
          url: `/assets/product-folder/Hundesnacks_${i18n.language.toUpperCase()}_2021.pdf`,
        });
        productArray.push({
          name: `${
            i18n.language === "de" ? "Die Offenbarung" : "Die Offenbarung"
          }`,
          requested: true,
          qty: 0,
          img: `/assets/product-images/Folder/${i18n.language}/Offenbarung.jpg`,
          // img: "/assets/product-folder/Offenbarung.jpg",
          url: `/assets/product-folder/Offenbarung_${i18n.language.toUpperCase()}_2021.pdf`,
        });
        productArray.push({
          name: `${
            i18n.language === "de" ? "Premium Pouches" : "Premium Pouches"
          }`,
          requested: true,
          qty: 0,
          img: `/assets/product-images/Folder/${i18n.language}/Premium-Pouches.jpg`,
          // img: "/assets/product-folder/Premium-Pouches.jpg",
          url: `/assets/product-folder/Premium-Pouches_${i18n.language.toUpperCase()}_2021.pdf`,
        });
        productArray.push({
          name: `${i18n.language === "de" ? "Small Dogs" : "Small Dogs"}`,
          requested: true,
          qty: 0,
          img: `/assets/product-images/Folder/${i18n.language}/SmallDogs.jpg`,
          // img: "/assets/product-folder/SmallDogs.jpg",
          url: `/assets/product-folder/SmallDogs_${i18n.language.toUpperCase()}_2021.pdf`,
        });
      }

      // if (category === "Ospelt Food International") {
      //   productArray.push({
      //     name: "Story Nonna Blanca",
      //     requested: true,
      //     qty: 0,
      //     img: "/assets/logos/nonna-blanca.png",
      //   });
      //   productArray.push({
      //     name: "Story Bio Fresh",
      //     requested: true,
      //     qty: 0,
      //     img: "/assets/logos/bio-fresh.png",
      //   });
      // }
      // if (category === "Ospelt Snacks International") {
      //   productArray.push({
      //     name: "Story Conower",
      //     requested: true,
      //     qty: 0,
      //     img: "/assets/logos/conower.png",
      //   });
      // }

      return {
        ...state,
        _id,
        code,
        name,
        email,
        company,
        category,
        manager,
        managerJob,
        managerMail,
        managerTel,
        managerMobile,
        products: productArray,
      };
    }

    case SET_LAST_WATCHED_VIDEO: {
      return {
        ...state,
        productVideos: {
          lastWatched: action.data,
        },
      };
    }
    case SET_PRODUCT_REQUESTED: {
      const { name, requested, qty } = action.data;
      const productsArray = [];
      const logString = [];

      const updateLog = async (id, data) => {
        try {
          await logService.save(id, data);
        } catch (e) {}
      };

      (function () {
        for (let i = 0; i < state.products.length; i++) {
          if (state.products[i].name === name) {
            productsArray.push({ ...state.products[i], name, requested, qty });
            logString.push(name + ` Requested: Yes `);
          } else {
            productsArray.push(state.products[i]);
            logString.push(
              state.products[i].name +
                ` Requested: ${state.products[i].requested ? "Yes " : "No "}`
            );
          }
        }
      })();

      updateLog(state._id, {
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
        description: `${name} wurde bestellt`,
        data: logString.toString(),
      });

      return {
        ...state,
        /* products: state.products.concat({ name, requested }), */
        products: productsArray,
      };
    }

    case ADD_PRODUCT_TO_CART: {
      const { name, requested } = action.data;
      return {
        ...state,
        productsInCart: state.productsInCart.concat({ name, requested }),
      };
    }

    case UPDATE_PRODUCT_VIDEOS_IN_CART: {
      const products = [];
      for (let i = 0; i < action.data.length; i++) {
        products.push({
          name: action.data[i].productName,
          requested: state.products[i].requested,
          qty: state.products[i].qty,
        });
      }
      return { ...state, products: products };
    }

    case UPDATE_PRODUCT_VIDEOS_IN_CART_REQUEST: {
      const updateLog = async (id, data) => {
        try {
          await logService.save(id, data);
        } catch (e) {}
      };

      const getElPositionInArray = state.products.findIndex(
        (o) => o.name === action.data.name
      );

      const products = state.products;
      products[getElPositionInArray].requested = action.data.requested;
      products[getElPositionInArray].qty = action.data.qty;

      updateLog(state._id, {
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
        description: `${state.products[getElPositionInArray].name} ${
          action.data.requested ? " Wurde bestellt" : " Wurde entfernt"
        }`,
      });

      return { ...state, products: products };
    }
    default:
      return { ...state };
  }
}
