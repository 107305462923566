import axios from "axios";
import { API_ROUTE } from "conf.js";

export default {
  save: async (id, data) => {
    let res = await axios.post(`${API_ROUTE}/log/${id}`, data);
    return res.data || [];
  },
  update: async (id, data) => {
    let res = await axios.put(`${API_ROUTE}/log/${id}`, data);
    return res.data || [];
  },
};
