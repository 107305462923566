export function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  console.log(is_ie);
  return is_ie;
}

export const rngNum = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const size = (width) => {
  if (width > 813) return 1920;
  if (width > 0) return 550;
};

export function isNumeric(s) {
  return !isNaN(s - parseFloat(s));
}

export const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

export const checkVersionIOS = () => {
  var agent = window.navigator.userAgent,
    start = agent.indexOf("OS ");
  if (
    (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
    start > -1
  ) {
    return window.Number(agent.substr(start + 3, 3).replace("_", "."));
  }
  return 0;
};

export const prefetch_file = (url, callback) => {
  let xhr = new XMLHttpRequest();

  xhr.open("GET", url);
  xhr.responseType = "blob";

  xhr.send();

  const toSlow = setTimeout(() => {
    return callback(2);
  }, 5000);

  xhr.onload = () => {
    if (xhr.status !== 200) {
      console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
    } else {
      console.log(`Done, got ${xhr.response} bytes`); // response is the server
      return clearTimeout(toSlow);
    }
  };

  xhr.onprogress = (event) => {
    if (event.lengthComputable) {
      console.log(`Received ${event.loaded} of ${event.total} bytes`);
    } else {
      console.log(`Received ${event.loaded} bytes`); // no Content-Length
    }
  };

  xhr.onerror = () => {
    console.log("Request failed");
  };
};

export function isLandscape() {
  const bs = browserVersion();
  if (bs) {
    return true;
  }
  // if (isIE()) return;
  var object =
    window.screen.orientation ||
    window.screen.msOrientation ||
    window.screen.mozOrientation ||
    false;
  if (object) {
    // console.log(object);
    if (object.type.indexOf("landscape") !== -1) {
      return true;
    }
    if (object.type.indexOf("portrait") !== -1) {
      return false;
    }
  }
  if ("orientation" in window) {
    var value = window.orientation;
    if (value === 0 || value === 180) {
      return false;
    } else if (value === 90 || value === 270) {
      return true;
    }
  }
  // fallback to comparing width to height
  return window.innerWidth > window.innerHeight;
}

export const displayKams = (kamName) => {
  // return kamName;
  const defaultName = "David von Meiss";
  switch (kamName) {
    case "Dominik Grunwald":
      return defaultName;
    case "Olivier Enderli":
      return defaultName;
    default:
      return kamName;
  }
};

export const displayMeissData = (type, manager, normalData) => {
  // return normalData;
  if (manager === "Dominik Grunwald" || manager === "Olivier Enderli") {
    switch (type) {
      case "name":
        return "David von Meiss";
      case "function":
        return "Head FOOD INTERNATIONAL";
      case "email":
        return "david.vonmeiss@ospelt.com";
      case "tel":
        return "+41 58 377 1121";
      case "mobile":
        return "+41 79 419 77 34";
      default:
        return null;
    }
  } else {
    return normalData;
  }
};

export const categoryVideo = (category) => {
  if (category === "Ospelt Food & Snacks International") {
    return "Ospelt Food International";
  } else {
    return category;
  }
};

export const browserVersion = () => {
  navigator.browserSpecs = (function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null)
        return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
  })();

  const bs = navigator.browserSpecs;
  // console.log(bs);
  if (bs.name === "Edge") {
    return bs;
  } else {
    return false;
  }
};

export const browser = () => {
  navigator.browserSpecs = (function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null)
        return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
  })();

  const bs = navigator.browserSpecs;

  return bs;
};
