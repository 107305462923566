export const SET_PERSONAL_DATA = "set_personal_data";
export const SET_LAST_WATCHED_VIDEO = "set_last_watched_video";
export const SET_CODE_DATA = "set_code_data";
export const SET_PRODUCT_REQUESTED = "set_product_requested";

export const ADD_PRODUCT_TO_CART = "add_product_to_cart";

export const UPDATE_PRODUCT_VIDEOS_IN_CART = "update_product_videos_in_cart";
export const UPDATE_PRODUCT_VIDEOS_IN_CART_REQUEST =
  "update_product_videos_in_cart_request";
