import { combineReducers } from "redux";
import formularReducer from "./formularReducer";
import videoReducer from "./videoReducer";
import codesReducer from "./codesReducer";

const rootReducer = combineReducers({
  formular: formularReducer,
  videoArray: videoReducer,
  codes: codesReducer,
});

export default rootReducer;
