import { SET_CODE_DATA } from "../types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CODE_DATA: {
      let validCodes = [];
      const codeList = {};

      for (let i = 0; action.data.length > i; i++) {
        validCodes.push(action.data[i].code);
        codeList[action.data[i].code] = action.data[i];
      }
      return { validCodes, ...codeList };
    }
    default:
      return { ...state };
  }
}
