// READ ONLY REDUCER

const petfoodEN = {
  meatyFlakes: "petfood1meatyflakesenglisch1920x1080",
  catsMonoProtein: "petfood2catsmonoproteinenglisch1920x1080",
  siroMonoProtein: "petfood3siromonoproteinenglisch1920x1080",
  sticksSelect: "petfood4sticksselectenglisch1920x1080",
  catsMousse: "petfood5catsmousseenglisch1920x1080",
  smallDogTrockenfutter: "petfood6smalldogtrockenfutterenglisch1920x1080",
  smallDogFeuchtNahrung: "petfood7smalldogfeuchtnahrungenglisch1920x1080",
  smallDogVeggie: "petfood8smalldogveggienglisch1920x1080",
  smallDogSticks: "petfood9smalldogsticksenglisch1920x1080",
  hundeSnacks: "petfood10hundesnacksenglisch1920x1080",
};

const foodEN = {
  bioFresh: "foodinternational1biofreshenglisch1920x1080",
  bamiGoreng: "foodinternational2plantbasedmealsenglisch1920x1080",
  nonnaBlanca: "foodinternational3nonnablancaenglisch1920x1080",
  beefJerky: "foodinternational4conowerjerkyenglisch1920x1080",
  pocketSandwich: "foodinternational5conowerpocketsandwichenglisch1920x1080",
  jerkyMix: "foodinternational6conowerjerkytrailmixenglisch1920x1080",
  partySticks: "foodinternational7conowerpartysticksenglisch1920x1080",
  bacon: "foodinternational8excellentbaconenglisch1920x1080",
  crispyChicken: "foodinternational9crispychickenenglisch1920x1080",
  streichwurst: "foodinternational10streichwurstenglisch1920x1080",
  leParfeitTuben: "foodinternational11leparfaittubenenglisch1920x1080",
  leParfeitDosen: "foodinternational12leparfaitdosenenglisch1920x1080",
};

// const snacksEN = {
//   jerky: 'snacksinternational1jerkyenglisch1920x1080',
//   partySticks: 'snacksinternational2partystickenglisch1920x1080',
//   pocketSandwich: 'snacksinternational3pocketsandwichenglisch1920x1080',
//   jerkyMix: 'snacksinternational4jerkytrailmixenglisch1920x1080',
// };

const petfoodDE = {
  meatyFlakes: "petfood1meatyflakesdeutsch1920x1080",
  catsMonoProtein: "petfood2catsmonoproteindeutsch1920x1080",
  siroMonoProtein: "petfood3siromonoproteindeutsch1920x1080",
  sticksSelect: "petfood4sticksselectdeutsch1920x1080",
  catsMousse: "petfood5catsmoussedeutsch1920x1080",
  smallDogTrockenfutter: "petfood6smalldogtrockenfutterdeutsch1920x1080",
  smallDogFeuchtNahrung: "petfood7smalldogfeuchtnahrungdeutsch1920x1080",
  smallDogVeggie: "petfood8smalldogveggideutsch1920x1080",
  smallDogSticks: "petfood9smalldogsticksdeutsch1920x1080",
  hundeSnacks: "petfood10hundesnacksdeutsch1920x1080",
};

const foodDE = {
  bioFresh: "foodinternational1biofreshdeutsch1920x1080",
  bamiGoreng: "foodinternational2plantbasedmealsdeutsch1920x1080",
  nonnaBlanca: "foodinternational3nonnablancadeutsch1920x1080",
  beefJerky: "foodinternational4conowerjerkydeutsch1920x1080",
  pocketSandwich: "foodinternational5conowerpocketsandwichdeutsch1920x1080",
  jerkyMix: "foodinternational6conowerjerkytrailmixdeutsch1920x1080",
  partySticks: "foodinternational7conowerpartysticksdeutsch1920x1080",
  bacon: "foodinternational8excellentbacondeutsch1920x1080",
  crispyChicken: "foodinternational9crispychickendeutsch1920x1080",
  streichwurst: "foodinternational10streichwurstdeutsch1920x1080",
  leParfeitTuben: "foodinternational11leparfaittubendeutsch1920x1080",
  leParfeitDosen: "foodinternational12leparfaitdosendeutsch1920x1080",
};

// const snacksDE = {
//   jerky: 'snacksinternational1jerkydeutsch1920x1080',
//   partySticks: 'snacksinternational2partysticksdeutsch1920x1080',
//   pocketSandwich: 'snacksinternational3pocketsandwichdeutsch1920x1080',
//   jerkyMix: 'snacksinternational4jerkytrailmixdeutsch1920x1080',
// };

export const mit = (name) => name + "/" + name + ".m3u8";
const F_HD = "1920x1080";
const HD = "1280x720";

const initialState = {
  en: {
    "Ospelt Petfood": [
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.meatyFlakes),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.meatyFlakes.replace(F_HD, HD)),
        },
        productName: "Cat’s Meaty Flakes",
        img: "/assets/product-images/Pet/Meaty_Flakes.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.catsMonoProtein),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.catsMonoProtein.replace(F_HD, HD)),
        },
        productName: "Cat’s Mono Protein",
        img: "/assets/product-images/Pet/Cats_Monoprotein.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.siroMonoProtein),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.siroMonoProtein.replace(F_HD, HD)),
        },
        productName: "Siro Mono Protein",
        img: "/assets/product-images/Pet/Siro_Monoprotein.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.sticksSelect),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.sticksSelect.replace(F_HD, HD)),
        },
        productName: "Siro Cat’s Stick Select",
        img: "/assets/product-images/Pet/EdelSticks.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.catsMousse),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.catsMousse.replace(F_HD, HD)),
        },
        productName: "Cat’s Mousse with jelly topping",
        img: "/assets/product-images/Pet/Cats_Mousse_mit_Softkern.jpg",
      },
      {
        src: {
          1920:
            "data/videos/petfood/en/" + mit(petfoodEN.smallDogTrockenfutter),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.smallDogTrockenfutter.replace(F_HD, HD)),
        },
        productName: "Siro Dry Food for small dogs",
        img: "/assets/product-images/Pet/Small_Dogs_Trockennahrung.jpg",
      },
      {
        src: {
          1920:
            "data/videos/petfood/en/" + mit(petfoodEN.smallDogFeuchtNahrung),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.smallDogFeuchtNahrung.replace(F_HD, HD)),
        },
        productName: "Siro Wet Food for small dogs",
        img: "/assets/product-images/Pet/Small_Dogs_Feuchtnahrung.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.smallDogVeggie),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.smallDogVeggie.replace(F_HD, HD)),
        },
        productName: "Siro Veggie for small dogs",
        img: "/assets/product-images/Pet/Small_Dogs_Veggie.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.smallDogSticks),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.smallDogSticks.replace(F_HD, HD)),
        },
        productName: "Siro Meaty Sticks for small dogs",
        img: "/assets/product-images/Pet/Small_Dogs_8er_Sticks.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/en/" + mit(petfoodEN.hundeSnacks),
          550:
            "data/videos/petfood/en/" +
            mit(petfoodEN.hundeSnacks.replace(F_HD, HD)),
        },
        productName: "Siro Dog Snacks",
        img: "/assets/product-images/Pet/Hundesnacks.jpg",
      },
    ],
    // 'Ospelt Food International': [
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.bioFresh),
    //       550:
    //         'data/videos/international/en/' +
    //         mit(foodEN.bioFresh.replace(F_HD, HD)),
    //     },
    //     productName: 'Nonna Blanca',
    //     img: '/assets/product-images/Food/NonnaBlanca.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.bamiGoreng),
    //       550:
    //         'data/videos/international/en/' +
    //         mit(foodEN.bamiGoreng.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Bami Goreng',
    //     img: '/assets/product-images/Food/OspeltFood.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.nonnaBlanca),
    //       550:
    //         'data/videos/international/en/' +
    //         mit(foodEN.nonnaBlanca.replace(F_HD, HD)),
    //     },
    //     productName: 'Bio Fresh',
    //     img: '/assets/product-images/Food/Kids_BioFresh.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.bacon),
    //       550:
    //         'data/videos/international/en/' + mit(foodEN.bacon.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Morning Bacon',
    //     img: '/assets/product-images/Food/Bacon.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.curryBalls),
    //       550:
    //         'data/videos/international/en/' +
    //         mit(foodEN.curryBalls.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Liver Meat Spread',
    //     img: '/assets/product-images/Food/Streichwurst.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/en/' + mit(foodEN.streichwurst),
    //       550:
    //         'data/videos/international/en/' +
    //         mit(foodEN.streichwurst.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Chicken Curry Balls',
    //     img: '/assets/product-images/Food/CurryBalls.jpg',
    //   },
    // ],
    // 'Ospelt Snacks International': [
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/en/' + mit(snacksEN.jerky),
    //       550: 'data/videos/snacks/en/' + mit(snacksEN.jerky.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Beef Jerky',
    //     img: '/assets/product-images/Snack/Jerky.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/en/' + mit(snacksEN.partySticks),
    //       550:
    //         'data/videos/snacks/en/' + mit(snacksEN.partySticks.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Party Sticks',
    //     img: '/assets/product-images/Snack/PartyStick.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/en/' + mit(snacksEN.pocketSandwich),
    //       550:
    //         'data/videos/snacks/en/' +
    //         mit(snacksEN.pocketSandwich.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Pocket Sandwich',
    //     img: '/assets/product-images/Snack/PocketSandwish.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix),
    //       550: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Jerky Trail Mix',
    //     img: '/assets/product-images/Snack/NussMix.jpg',
    //   },
    // ],
    "Ospelt Food & Snacks International": [
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.bioFresh),
          550:
            "data/videos/international/en/" +
            mit(foodEN.bioFresh.replace(F_HD, HD)),
        },
        productName: "Bio Fresh Kids Meal",
        img: "/assets/product-images/Food/Kids_Gemuesereis.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.bamiGoreng),
          550:
            "data/videos/international/en/" +
            mit(foodEN.bamiGoreng.replace(F_HD, HD)),
        },
        productName: "Plant based Meals",
        img: "/assets/product-images/Food/Plant_Based_Meals.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.nonnaBlanca),
          550:
            "data/videos/international/en/" +
            mit(foodEN.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: "Nonna Blanca Pasta",
        img: "/assets/product-images/Food/NonnaBlanca.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.beefJerky),
          550:
            "data/videos/international/en/" +
            mit(foodEN.beefJerky.replace(F_HD, HD)),
        },
        productName: "Conower Beef Jerky",
        img: "/assets/product-images/Food/Conower_Jerky.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.pocketSandwich),
          550:
            "data/videos/international/en/" +
            mit(foodEN.pocketSandwich.replace(F_HD, HD)),
        },
        productName: "Conower Pocket Sandwich Classic",
        img: "/assets/product-images/Food/Pocket_Sandwich.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.jerkyMix),
          550:
            "data/videos/international/en/" +
            mit(foodEN.jerkyMix.replace(F_HD, HD)),
        },
        productName: "Nuss/Jerky Trail Mix",
        img: "/assets/product-images/Food/Nussjerky_Trail_Mix.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.partySticks),
          550:
            "data/videos/international/en/" +
            mit(foodEN.partySticks.replace(F_HD, HD)),
        },
        productName: "Conower Party Sticks",
        img: "/assets/product-images/Food/PartySticks_Classic.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.bacon),
          550:
            "data/videos/international/en/" +
            mit(foodEN.bacon.replace(F_HD, HD)),
        },
        productName: "Excellent Bacon",
        img: "/assets/product-images/Food/Excellent_Bacon.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.crispyChicken),
          550:
            "data/videos/international/en/" +
            mit(foodEN.crispyChicken.replace(F_HD, HD)),
        },
        productName: "Crispy Chicken Hot n Spicy",
        img: "/assets/product-images/Food/Crispy_Chicken.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.streichwurst),
          550:
            "data/videos/international/en/" +
            mit(foodEN.streichwurst.replace(F_HD, HD)),
        },
        productName: "Liver-Meat Spread Squeeze",
        img: "/assets/product-images/Food/Streichwurst_Squeeze.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.leParfeitTuben),
          550:
            "data/videos/international/en/" +
            mit(foodEN.leParfeitTuben.replace(F_HD, HD)),
        },
        productName: "Le Parfeit Tubes",
        img: "/assets/product-images/Food/LeParfait.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/en/" + mit(foodEN.leParfeitDosen),
          550:
            "data/videos/international/en/" +
            mit(foodEN.leParfeitDosen.replace(F_HD, HD)),
        },
        productName: "Le Parfeit Cans",
        img: "/assets/product-images/Food/LeParfait_Dosen.jpg",
        category: "haltbar",
      },
    ],
  },
  de: {
    "Ospelt Petfood": [
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.meatyFlakes),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.meatyFlakes.replace(F_HD, HD)),
        },
        productName: "Cat’s Meaty Flakes",
        img: "/assets/product-images/Pet/Meaty_Flakes.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.catsMonoProtein),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.catsMonoProtein.replace(F_HD, HD)),
        },
        productName: "Cat’s Mono Protein",
        img: "/assets/product-images/Pet/Cats_Monoprotein.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.siroMonoProtein),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.siroMonoProtein.replace(F_HD, HD)),
        },
        productName: "Siro Mono Protein",
        img: "/assets/product-images/Pet/Siro_Monoprotein.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.sticksSelect),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.sticksSelect.replace(F_HD, HD)),
        },
        productName: "Siro Cat’s Stick Select",
        img: "/assets/product-images/Pet/EdelSticks.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.catsMousse),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.catsMousse.replace(F_HD, HD)),
        },
        productName: "Cat’s Mousse mit Gelee Haube",
        img: "/assets/product-images/Pet/Cats_Mousse_mit_Softkern.jpg",
      },
      {
        src: {
          1920:
            "data/videos/petfood/de/" + mit(petfoodDE.smallDogTrockenfutter),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.smallDogTrockenfutter.replace(F_HD, HD)),
        },
        productName: "Siro Trockennahrung für kleine Hunde",
        img: "/assets/product-images/Pet/Small_Dogs_Trockennahrung.jpg",
      },
      {
        src: {
          1920:
            "data/videos/petfood/de/" + mit(petfoodDE.smallDogFeuchtNahrung),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.smallDogFeuchtNahrung.replace(F_HD, HD)),
        },
        productName: "Siro Feuchtnahrung für kleine Hunde",
        img: "/assets/product-images/Pet/Small_Dogs_Feuchtnahrung.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.smallDogVeggie),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.smallDogVeggie.replace(F_HD, HD)),
        },
        productName: "Siro Veggie für kleine Hunde",
        img: "/assets/product-images/Pet/Small_Dogs_Veggie.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.smallDogSticks),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.smallDogSticks.replace(F_HD, HD)),
        },
        productName: "Siro Meaty Sticks für kleine Hunde",
        img: "/assets/product-images/Pet/Small_Dogs_8er_Sticks.jpg",
      },
      {
        src: {
          1920: "data/videos/petfood/de/" + mit(petfoodDE.hundeSnacks),
          550:
            "data/videos/petfood/de/" +
            mit(petfoodDE.hundeSnacks.replace(F_HD, HD)),
        },
        productName: "Siro Hundesnacks",
        img: "/assets/product-images/Pet/Hundesnacks.jpg",
      },
    ],
    // 'Ospelt Food International': [
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.bioFresh),
    //       550:
    //         'data/videos/international/de/' +
    //         mit(foodDE.bioFresh.replace(F_HD, HD)),
    //     },
    //     productName: 'Nonna Blanca',
    //     img: '/assets/product-images/Food/NonnaBlanca.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.bamiGoreng),
    //       550:
    //         'data/videos/international/de/' +
    //         mit(foodDE.bamiGoreng.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Bami Goreng',
    //     img: '/assets/product-images/Food/OspeltFood.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.nonnaBlanca),
    //       550:
    //         'data/videos/international/de/' +
    //         mit(foodDE.nonnaBlanca.replace(F_HD, HD)),
    //     },
    //     productName: 'Bio Fresh',
    //     img: '/assets/product-images/Food/Kids_BioFresh.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.bacon),
    //       550:
    //         'data/videos/international/de/' + mit(foodDE.bacon.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Frühstücks Bacon',
    //     img: '/assets/product-images/Food/Bacon.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.curryBalls),
    //       550:
    //         'data/videos/international/de/' +
    //         mit(foodDE.curryBalls.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Streichwurst',
    //     img: '/assets/product-images/Food/Streichwurst.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/international/de/' + mit(foodDE.streichwurst),
    //       550:
    //         'data/videos/international/de/' +
    //         mit(foodDE.streichwurst.replace(F_HD, HD)),
    //     },
    //     productName: 'Ospelt Chicken Curry Balls',
    //     img: '/assets/product-images/Food/CurryBalls.jpg',
    //   },
    // ],
    // 'Ospelt Snacks International': [
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/de/' + mit(snacksDE.jerky),
    //       550: 'data/videos/snacks/de/' + mit(snacksDE.jerky.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Beef Jerky',
    //     img: '/assets/product-images/Snack/Jerky.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/de/' + mit(snacksDE.partySticks),
    //       550:
    //         'data/videos/snacks/de/' + mit(snacksDE.partySticks.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Partysticks',
    //     img: '/assets/product-images/Snack/PartyStick.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/de/' + mit(snacksDE.pocketSandwich),
    //       550:
    //         'data/videos/snacks/de/' +
    //         mit(snacksDE.pocketSandwich.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Pocket Sandwich',
    //     img: '/assets/product-images/Snack/PocketSandwish.jpg',
    //   },
    //   {
    //     src: {
    //       1920: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix),
    //       550: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix.replace(F_HD, HD)),
    //     },
    //     productName: 'Conower Jerky Trail Mix',
    //     img: '/assets/product-images/Snack/NussMix.jpg',
    //   },
    // ],
    "Ospelt Food & Snacks International": [
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.bioFresh),
          550:
            "data/videos/international/de/" +
            mit(foodDE.bioFresh.replace(F_HD, HD)),
        },
        productName: "Bio Fresh Kids Meal",
        img: "/assets/product-images/Food/Kids_Gemuesereis.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.bamiGoreng),
          550:
            "data/videos/international/de/" +
            mit(foodDE.bamiGoreng.replace(F_HD, HD)),
        },
        productName: "Plant based Meals",
        img: "/assets/product-images/Food/Plant_Based_Meals.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.nonnaBlanca),
          550:
            "data/videos/international/de/" +
            mit(foodDE.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: "Nonna Blanca Pasta",
        img: "/assets/product-images/Food/NonnaBlanca.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.beefJerky),
          550:
            "data/videos/international/de/" +
            mit(foodDE.beefJerky.replace(F_HD, HD)),
        },
        productName: "Conower Beef Jerky",
        img: "/assets/product-images/Food/Conower_Jerky.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.pocketSandwich),
          550:
            "data/videos/international/de/" +
            mit(foodDE.pocketSandwich.replace(F_HD, HD)),
        },
        productName: "Conower Pocket Sandwich Classic",
        img: "/assets/product-images/Food/Pocket_Sandwich.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.jerkyMix),
          550:
            "data/videos/international/de/" +
            mit(foodDE.jerkyMix.replace(F_HD, HD)),
        },
        productName: "Nuss/Jerky Trail Mix",
        img: "/assets/product-images/Food/Nussjerky_Trail_Mix.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.partySticks),
          550:
            "data/videos/international/de/" +
            mit(foodDE.partySticks.replace(F_HD, HD)),
        },
        productName: "Conower Party Sticks",
        img: "/assets/product-images/Food/PartySticks_Classic.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.bacon),
          550:
            "data/videos/international/de/" +
            mit(foodDE.bacon.replace(F_HD, HD)),
        },
        productName: "Exellent Bacon",
        img: "/assets/product-images/Food/Excellent_Bacon.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.crispyChicken),
          550:
            "data/videos/international/de/" +
            mit(foodDE.crispyChicken.replace(F_HD, HD)),
        },
        productName: "Crispy Chicken Hot n Spicy",
        img: "/assets/product-images/Food/Crispy_Chicken.jpg",
        category: "nicht haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.streichwurst),
          550:
            "data/videos/international/de/" +
            mit(foodDE.streichwurst.replace(F_HD, HD)),
        },
        productName: "Streichwurst Squeeze",
        img: "/assets/product-images/Food/Streichwurst_Squeeze.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.leParfeitTuben),
          550:
            "data/videos/international/de/" +
            mit(foodDE.leParfeitTuben.replace(F_HD, HD)),
        },
        productName: "Le Parfeit Tuben",
        img: "/assets/product-images/Food/LeParfait.jpg",
        category: "haltbar",
      },
      {
        src: {
          1920: "data/videos/international/de/" + mit(foodDE.leParfeitDosen),
          550:
            "data/videos/international/de/" +
            mit(foodDE.leParfeitDosen.replace(F_HD, HD)),
        },
        productName: "Le Parfeit Dosen",
        img: "/assets/product-images/Food/LeParfait_Dosen.jpg",
        category: "haltbar",
      },
    ],
  },
};

export default function (state = initialState) {
  return { ...state };
}
